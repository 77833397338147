<template>
  <div class="tools02-pixels">
    <el-row>
      <el-col>
        <h2 v-if="aspId === '0'">カート・計測システム連携のお申し込み</h2>
        <h2 v-else>{{ aspData.aspName }}のお申し込み</h2>
      </el-col>
    </el-row>
    <Tools02CartSystem :aspId="aspId" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tools02CartSystem from "@/components/Tools02/CartSystem/Contents.vue";

export default {
  components: {
    Tools02CartSystem,
  },
  props: {
    aspId: String,
  },
  data() {
    return {
      // 
    };
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
    aspData() {
      return this.$store.getters.aspDataFromAllAspList(this.aspId);
    }
  },
  methods: {},
};

</script>

<style lang="scss" scoped>
</style>
