<template>
  <div class="ui-tools02-subscription-history-list">
    <el-row :gutter="18">
      <el-col style="width: 170px">
        <a href="https://adtasukaru.wraptas.site/c692cf0cf6c14d6ea9aea46081d4e112" target="_blank"
          rel="noopener noreferrer">
          <Button2 type2="1" class="manual-button" msg="マニュアル" />
        </a>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="24">
        <el-button @click="dateRange = g_getDateRange(59)" type="primary">全件</el-button>
        <el-button @click="dateRange = g_getDateRange('thisMonth')" type="primary">今月</el-button>
        <el-button @click="dateRange = g_getDateRange('lastMonth')" type="primary">先月</el-button>
        <el-button @click="dateRange = g_getDateRange(29)" type="primary">過去３０日</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-date-picker v-model="dateRange" type="daterange" align="right" start-placeholder="開始日" end-placeholder="終了日"
          value-format="yyyy-MM-dd" class="data"></el-date-picker>
      </el-col>
    </el-row>
    <!-- <el-row>
      <el-col style="text-align: center">
        <el-button type="primary">検索</el-button>
      </el-col>
    </el-row> -->
    <el-table :data="displaySubscriptionHistoryList" style="width: 100%">
      <el-table-column fixed prop="date" label="日付" width="150">
      </el-table-column>
      <el-table-column label="Google広告">
        <el-table-column label="利用">
          <template slot-scope="scope">
            <span>{{ scope.row.googleValidity === true ? "ON" : "OFF" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="追加">
          <template slot-scope="scope">
            <el-button v-if="
              scope.row.googleValidity === false &&
              isDateBeforeToday(scope.row.date) === true
            " @click="addSubscription('7', scope.row.date)" type="success" size="mini">追加</el-button>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Yahoo!広告">
        <el-table-column label="利用">
          <template slot-scope="scope">
            <span>{{ scope.row.yahooValidity === true ? "ON" : "OFF" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="追加">
          <template slot-scope="scope">
            <el-button v-if="
              scope.row.yahooValidity === false &&
              isDateBeforeToday(scope.row.date) === true
            " @click="addSubscription('10', scope.row.date)" type="success" size="mini">追加</el-button>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Meta広告">
        <el-table-column label="利用">
          <template slot-scope="scope">
            <span>{{
              scope.row.facebookValidity === true ? "ON" : "OFF"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="追加">
          <template slot-scope="scope">
            <el-button v-if="
              scope.row.facebookValidity === false &&
              isDateBeforeToday(scope.row.date) === true
            " @click="addSubscription('8', scope.row.date)" type="success" size="mini">追加</el-button>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Microsoft広告">
        <el-table-column label="利用">
          <template slot-scope="scope">
            <span>{{
              scope.row.microsoftValidity === true ? "ON" : "OFF"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="追加">
          <template slot-scope="scope">
            <el-button v-if="
              scope.row.microsoftValidity === false &&
              isDateBeforeToday(scope.row.date) === true
            " @click="addSubscription('12', scope.row.date)" type="success" size="mini">追加</el-button>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column v-if="
        userData.userId === '1' ||
        userData.userId === '2' ||
        userData.userId === '3' ||
        userData.userId === '5'
      " label="LINE広告">
        <el-table-column label="利用">
          <template slot-scope="scope">
            <span>{{ scope.row.lapValidity === true ? "ON" : "OFF" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="追加">
          <template slot-scope="scope">
            <el-button v-if="
              scope.row.lapValidity === false &&
              isDateBeforeToday(scope.row.date) === true
            " @click="addSubscription('14', scope.row.date)" type="success" size="mini">追加</el-button>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="TikTok広告">
        <el-table-column label="利用">
          <template slot-scope="scope">
            <span>{{ scope.row.tiktokValidity === true ? "ON" : "OFF" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="追加">
          <template slot-scope="scope">
            <el-button v-if="
              scope.row.tiktokValidity === false &&
              isDateBeforeToday(scope.row.date) === true
            " @click="addSubscription('11', scope.row.date)" type="success" size="mini">追加</el-button>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="YDA">
        <el-table-column label="利用">
          <template slot-scope="scope">
            <span>{{ scope.row.ydaValidity === true ? "ON" : "OFF" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="追加">
          <template slot-scope="scope">
            <el-button v-if="
              scope.row.ydaValidity === false &&
              isDateBeforeToday(scope.row.date) === true
            " @click="addSubscription('15', scope.row.date)" type="success" size="mini">追加</el-button>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Squadbeyond">
        <el-table-column label="利用">
          <template slot-scope="scope">
            <span>{{
              scope.row.squadbeyondValidity === true ? "ON" : "OFF"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="追加">
          <template slot-scope="scope">
            <el-button v-if="
              scope.row.squadbeyondValidity === false &&
              isDateBeforeToday(scope.row.date) === true
            " @click="addSubscription('16', scope.row.date)" type="success" size="mini">追加</el-button>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <!-- <el-table :data="subscriptionHistoryList" border>
      <el-table-column fixed label="" min-width="160px" prop="date">
      </el-table-column>
      <el-table-column label="利用">
        <template slot-scope="scope">
          <span v-if="scope.row.validity === true">ON</span>
          <span v-else>OFF</span>
        </template>
      </el-table-column>
      <el-table-column label="">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.validity === false"
            @click="addSubscription(scope.row.date)"
            type="success"
            size="mini"
            >追加</el-button
          >
        </template>
      </el-table-column>
    </el-table> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";

export default {
  name: "ui-tools02-subscription-history-list",
  components: {
    Button2,
  },
  props: {},
  data() {
    return {
      dateRange: [],
      targetToolIdList: ["7", "10", "8", "12", "14", "11", "15", "16"],
      displaySubscriptionHistoryList: [],
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "userData"]),
    // ...mapGetters("tools02", ["subscriptionHistoryList"]),
    subscriptionHistoryList() {
      // 
      let subscriptionHistoryList = [];

      // 
      let listDays = this.$store.getters.constData.tools02.subscription.listDays;
      switch (this.userData.userId) {
        case '1':
        case '770':
          listDays = 90;
          break;
      
        default:
          // no action.
          break;
      }

      // 
      for (let i = 0; i < listDays; i++) {
        let date = new Date();
        date.setDate(date.getDate() - i);

        //
        let subscriptionHistoryData = {
          date: date.toLocaleDateString("ja-JP"),
          googleValidity: false,
          facebookValidity: false,
          yahooValidity: false,
          tiktokValidity: false,
          microsoftValidity: false,
          lapValidity: false,
          ydaValidity: false,
          squadbeyondValidity: false,
        };

        //
        for (let toolId of this.targetToolIdList) {
          //
          let subscriptionData = this.$store.getters[
            "tools02/subscriptionData"
          ](toolId, date.toLocaleDateString("ja-JP"));
          if (subscriptionData.length > 0) {
            switch (toolId) {
              case "7":
                subscriptionHistoryData.googleValidity = true;
                break;
              case "8":
                subscriptionHistoryData.facebookValidity = true;
                break;
              case "10":
                subscriptionHistoryData.yahooValidity = true;
                break;
              case "11":
                subscriptionHistoryData.tiktokValidity = true;
                break;
              case "12":
                subscriptionHistoryData.microsoftValidity = true;
                break;
              case "14":
                subscriptionHistoryData.lapValidity = true;
                break;
              case "15":
                subscriptionHistoryData.ydaValidity = true;
                break;
              case "16":
                subscriptionHistoryData.squadbeyondValidity = true;
                break;

              default:
                //
                break;
            }
          } else {
            // no action.
          }
        }

        //
        subscriptionHistoryList.push(subscriptionHistoryData);
      }
      return subscriptionHistoryList;
    },
  },
  watch: {
    dateRange() {
      this.updateDisplaySubscriptionHistoryList();
    },
  },
  created() {
    this.dateRange = this.g_getDateRange("30");
    this.updateDisplaySubscriptionHistoryList();
  },
  methods: {
    updateDisplaySubscriptionHistoryList() {
      const startDate = new Date(this.dateRange[0] + " 00:00:00");
      const endDate = new Date(this.dateRange[1] + " 00:00:00");

      //
      const displaySubscriptionHistoryList =
        this.subscriptionHistoryList.filter((subscriptionHistoryData) => {
          const subscriptionHistoryDate = new Date(
            subscriptionHistoryData.date + " 00:00:00"
          );
          return (
            startDate.getTime() <= subscriptionHistoryDate.getTime() &&
            subscriptionHistoryDate.getTime() <= endDate.getTime()
          );
        });

      this.displaySubscriptionHistoryList = displaySubscriptionHistoryList;
    },
    addSubscription(toolId, date) {
      //
      let toolData = this.$store.getters.toolData(toolId);
      let toolName = toolData.name.replace("成果連携ツール", "");
      toolName = toolName.replace("コンバージョンAPI連携ツール", "");
      let message = `${toolData.point}ポイント消化します。\n${date}の${toolName}の成果をインポートすることができます。`;

      //
      if (confirm(message)) {
        let params = new URLSearchParams();
        params.append("tool_id", toolId);
        params.append("date", date);
        this.$axios
          .post(`${this.rt}/api/subscription/register-subscription.php`, params)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              alert("追加しました。");
              this.$store
                .dispatch("tools02/setSubscriptionList")
                .then((response) => {
                  console.log(response);
                  this.updateDisplaySubscriptionHistoryList();
                })
                .catch((error) => {
                  console.log(error);
                });
              this.$store.dispatch("setUserData");
              this.$store.dispatch("setStockPoint");
              return;
            } else if (response.data.status === 400) {
              alert(response.data.message);
              return;
            } else if (response.data.status === 401) {
              alert(response.data.message);
              this.$router.push("/login");
            } else {
              alert(this.apiErrorMessage);
              return;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // no action.
      }
    },
    isDateBeforeToday(dateToCheck) {
      // 現在時刻を取得
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      console.log(today);

      // 指定した日付をDateオブジェクトに変換
      const dateA = new Date(dateToCheck);
      console.log(dateA);

      // 日付を比較し、dateAが現在時刻より前であるかをチェック
      return dateA < today;
    },
  },
};
</script>

<style lang="scss" scoped></style>
