<template>
  <div class="tools02-report-export">
    <el-row style="margin: 0">
      <el-col class="sub-title">
        <div style="margin-top: 65px">
          <h3 v-if="
            tool.codeKebab === 'google-cv' ||
            tool.codeKebab === 'yahoo-cv' ||
            tool.codeKebab === 'microsoft-cv'
          ">
            Step3：{{
              tool.mediaName
            }}のアップロード形式に変換したASP成果データのエクスポート
          </h3>
          <h3 v-else-if="
            tool.codeKebab === 'facebook-cv' ||
            tool.codeKebab === 'tiktok-cv' ||
            tool.codeKebab === 'line-cv' ||
            tool.codeKebab === 'yda-cv'
          ">
            <span>Step3：</span>
            <span v-if="tool.codeKebab === 'line-cv'">LINE広告</span>
            <span v-else>{{ tool.mediaName }}</span>
            <span>のレポートを検索</span>
          </h3>
        </div>
        <a :href="toolData.parameterPassingType === '0'
          ? toolData.manualData.mediaUploadFileExportManualUrl
          : toolData.manualData.howToCheckImportedReportManualUrl
          " target="_blank" rel="noopener noreferrer">
          <p class="manual">【マニュアル】</p>
        </a>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="date">
          データの保存期間は過去60日分です。過去60日分までエクスポート可能です。
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col class="today">
        <el-button class="today-bt" @click="getDateRange(1)">今日</el-button>
        <el-button class="today-bt" @click="getDateRange(2)">昨日</el-button>
        <el-button class="today-bt" @click="getDateRange(3)">今日・昨日</el-button>
        <el-button class="today-bt" @click="getDateRange(4)">今月</el-button>
        <el-button class="today-bt" @click="getDateRange(5)">先月</el-button>
        <el-button class="today-bt" @click="getDateRange(0)">全期間</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-date-picker v-model="dateRange" type="daterange" align="right" start-placeholder="Start Date"
          end-placeholder="End Date" value-format="yyyy-MM-dd"></el-date-picker>
      </el-col>
    </el-row>
    <el-row v-if="
      tool.codeKebab === 'google-cv' ||
      tool.codeKebab === 'yahoo-cv' ||
      tool.codeKebab === 'microsoft-cv' ||
      tool.codeKebab === 'yda-cv'
    ">
      <el-col>
        <div class="el-select-prepend-cover">
          <div class="el-select-prepend">アカウント</div>
          <el-select v-model="accountId" placeholder="全アカウント">
            <el-option :key="0" :label="`全アカウント`" :value="''" class="select"></el-option>
            <el-option v-for="account in accounts" :key="account.accountId" :label="account.accountName"
              :value="account.accountId"></el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="el-select-prepend-cover">
          <div class="el-select-prepend">ASP</div>
          <el-select v-model="aspId" placeholder="選択してください。">
            <el-option :key="0" :label="`全ASP`" :value="''" class="select"></el-option>
            <el-option v-for="asp in supportingImportAspList" :key="asp.aspId" :label="asp.aspName"
              :value="asp.aspId"></el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col style="text-align: center">
        <el-button @click="getReportList('default', 1)" type="primary">検索</el-button>
      </el-col>
      <el-col v-show="totalCount > 0" style="text-align: center; margin-top: 15px">
        <el-button v-if="
          tool.codeKebab === 'google-cv' ||
          tool.codeKebab === 'yahoo-cv' ||
          tool.codeKebab === 'microsoft-cv' ||
          tool.codeKebab === 'yda-cv'
        " @click="getReportList('csv', 0)" type="success">エクスポート</el-button>
      </el-col>
    </el-row>

    <el-row v-show="totalCount > 0">
      <el-col>
        <div style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          ">
          <el-pagination :page-size="pageSize" :pager-count="pagerCount" :total="totalCount"
            :current-page.sync="currentPage" layout="prev, pager, next" />
          <div>全 {{ totalCount | localeNum }} 件</div>
        </div>
        <table class="total_tbl">
          <thead>
            <tr>
              <th v-if="tool.codeKebab === 'facebook-cv'" style="width: 165px;">atid</th>
              <th>
                <span v-if="tool.codeKebab === 'google-cv'">クリックID</span>
                <span v-else-if="tool.codeKebab === 'facebook-cv'">FBCLID</span>
                <span v-else-if="tool.codeKebab === 'yahoo-cv'">YCLID</span>
                <span v-else-if="tool.codeKebab === 'tiktok-cv'">ttclid</span>
                <span v-else-if="tool.codeKebab === 'microsoft-cv'">MSCLKID</span>
                <span v-else-if="tool.codeKebab === 'line-cv'">LDTAGCL</span>
                <span v-else-if="tool.codeKebab === 'yda-cv'">YCLID</span>
              </th>
              <th v-if="tool.codeKebab === 'google-cv'">種別</th>
              <th v-if="
                tool.codeKebab === 'google-cv' ||
                tool.codeKebab === 'yahoo-cv' ||
                tool.codeKebab === 'microsoft-cv'
              ">
                オフラインCV名
              </th>
              <th v-else-if="
                tool.codeKebab === 'facebook-cv' ||
                tool.codeKebab === 'tiktok-cv' ||
                tool.codeKebab === 'line-cv' ||
                tool.codeKebab === 'yda-cv'
              ">
                プログラム名
              </th>
              <th>CV時間</th>
              <th v-if="
                tool.codeKebab === 'facebook-cv' ||
                tool.codeKebab === 'tiktok-cv' ||
                tool.codeKebab === 'line-cv'
              ">
                CVページURL
              </th>
              <th>報酬単価</th>
              <th>承認率</th>
              <th>報酬</th>
              <th v-if="
                tool.codeKebab === 'google-cv' ||
                tool.codeKebab === 'yahoo-cv' ||
                tool.codeKebab === 'microsoft-cv'
              ">
                アカウント名
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(reportData, reportIndex) in reportList" :key="reportIndex">
              <td v-if="tool.codeKebab === 'facebook-cv'">{{ reportData.adtasukaruId }}</td>
              <td>
                <div style="width: 250px; word-break: break-all; margin: 0 auto">
                  {{ reportData.gclid }}
                </div>
              </td>
              <td v-if="tool.codeKebab === 'google-cv'">
                <div v-if="reportData.clickIdType === '0'">gclid</div>
                <div v-else-if="reportData.clickIdType === '1'">wbraid</div>
                <div v-else-if="reportData.clickIdType === '2'">gbraid</div>
              </td>
              <td v-if="
                tool.codeKebab === 'google-cv' ||
                tool.codeKebab === 'yahoo-cv' ||
                tool.codeKebab === 'microsoft-cv'
              ">
                <div>{{ reportData.promotionData.cvName }}</div>
              </td>
              <td v-else-if="
                tool.codeKebab === 'facebook-cv' ||
                tool.codeKebab === 'tiktok-cv' ||
                tool.codeKebab === 'line-cv' ||
                tool.codeKebab === 'yda-cv'
              ">
                <div>{{ reportData.promotionData.promotionName }}</div>
              </td>
              <td>
                <div v-if="reportData.reportId !== editingConversionTimeReportData.reportId">
                  <span>{{ reportData.formattedConversionTime }}</span>
                  <div v-if="tool.codeKebab === 'google-cv'"><el-button @click="editConversionTime(reportData)"
                      size="mini">編集する</el-button></div>
                </div>
                <div v-else class="conversion-time-editor">
                  <span>{{ editingConversionTimeReportData.date }}</span>
                  <br>
                  <span><el-input v-model="editingConversionTimeReportData.hour" type="number" min="0" max="23"
                      size="mini"></el-input>:</span>
                  <span><el-input v-model="editingConversionTimeReportData.minute" type="number" min="0" max="59"
                      size="mini"></el-input>:</span>
                  <span>00</span>
                  <div class="update-button"><el-button @click="updateConversionTime(reportData)" type="primary"
                      size="mini">決定</el-button></div>
                </div>
              </td>
              <td v-show="tool.codeKebab === 'facebook-cv' ||
                tool.codeKebab === 'tiktok-cv' ||
                tool.codeKebab === 'line-cv'
                ">
                <div>{{ reportData.promotionData.cvPageUrl }}</div>
              </td>
              <td>
                <div>{{ reportData.conversionValue | localeNum }}</div>
              </td>
              <td>
                <div>{{ reportData.approvalRate }}%</div>
              </td>
              <td>
                <div>
                  {{
                    Math.floor(
                      (reportData.conversionValue * reportData.approvalRate) /
                      100
                    ) | localeNum
                  }}
                </div>
              </td>
              <td v-if="
                tool.codeKebab === 'google-cv' ||
                tool.codeKebab === 'yahoo-cv' ||
                tool.codeKebab === 'microsoft-cv'
              ">
                <div v-if="reportData.accountData !== null">{{ reportData.accountData.accountName }}</div>
                <div v-else>-</div>
              </td>
              <td>
                <div>
                  <el-button @click="
                    delReport(
                      reportData.reportId,
                      reportData.cvName,
                      reportData.conversionTime
                    )
                    " type="danger" size="mini">削除</el-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <el-pagination :page-size="pageSize" :pager-count="pagerCount" :total="totalCount"
          :current-page.sync="currentPage" layout="prev, pager, next" />
      </el-col>
    </el-row>
    <el-row v-if="
      tool.codeKebab === 'google-cv' ||
      tool.codeKebab === 'yahoo-cv' ||
      tool.codeKebab === 'microsoft-cv'
    " class="last-step">
      <el-col class="sub-title">
        <h3>Step4：{{ tool.mediaName }}へASP成果データをアップロード</h3>
        <a :href="toolData.manualData.mediaUploadManualUrl" target="_blank" rel="noopener noreferrer">
          <p class="manual">【マニュアル】</p>
        </a>
      </el-col>
      <el-col>
        <p class="description">
          アドタスカルからエクスポートしたファイルを{{
            tool.mediaName
          }}にアップロードしてください。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {},
  data() {
    return {
      //
      pageSize: 100,
      pagerCount: 7,
      currentPage: 1,

      // ----- ↓↓ 検索 ↓↓ -----
      dateRange: [],
      userId: "",
      accountId: "",
      promotionId: "",
      aspId: "",
      clickIdTypeList: ["0", "1", "2"],
      // ----- ↑↑ 検索 ↑↑ -----

      //
      reportList: [],
      totalCount: 0,

      //
      editingConversionTimeReportData: {
        reportId: null,
        date: '',
        hour: '',
        minute: '',
      },


      // 
      totalOfFailedFacebookApi: 0,
    };
  },
  computed: {
    ...mapGetters([
      "rt",
      "tool",
      "apiErrorMessage",
      "tools",
      "supportingImportAspList",
    ]),
    accounts() {
      return this.$store.getters["tools02/accounts"](this.tool.id);
    },
    toolData() {
      return this.$store.getters["toolData"](this.tool.id);
    },
  },
  watch: {
    currentPage(newCurrentPage) {
      this.getReportList("default", newCurrentPage);
    },
  },
  methods: {
    getDateRange(type) {
      this.$axios
        .get(`${this.rt}/api/tools02/getDateRange.php?type=${type}`)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.dateRange = res.data.dateRange;
            return;
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getReportList(mode, currentPage) {
      this.currentPage = currentPage;

      //
      let dateTimeRange = [];
      if (this.dateRange.length === 2) {
        let startDatetime = this.dateRange[0] + " 00:00:00";
        let endDatetime = this.dateRange[1] + " 23:59:59";
        dateTimeRange = [startDatetime, endDatetime];
      } else {
        alert("表示する期間を選択してください。");
        return;
      }

      //
      let url = `${this.rt}/l/api/tools02/report/get-report-list`;
      url += `?mode=${mode}`;
      url += `&datetime_range=${JSON.stringify(dateTimeRange)}`;
      let userId = this.userId === "" ? "0" : this.userId;
      url += `&user_id=${userId}`;
      url += `&tool_id=${this.tool.id}`;
      url += `&click_id_type_list=${JSON.stringify(this.clickIdTypeList)}`;
      url += `&account_id=${this.accountId === "" ? "0" : this.accountId}`;
      let promotionId = this.promotionId === "" ? "0" : this.promotionId;
      url += `&promotion_id=${promotionId}`;
      url += `&asp_id=${this.aspId === "" ? "0" : this.aspId}`;
      url += `&current_page=${currentPage}`;
      url += `&page_size=${this.pageSize}`;

      //
      this.$axios
        .get(url)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            if (mode === "default") {
              if (response.data.totalCount > 0) {
                this.totalCount = response.data.totalCount;
                this.reportList = response.data.reportList;
              } else {
                alert("該当するレポートはありませんでした。");
              }
            } else if (mode === "csv") {
              location.href = `${this.rt}/l/api/file/download`;
            } else {
              console.log("???");
            }
            return;
          } else if (response.data.status === 400) {
            alert(response.data.message);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    delReport(reportId, cvName, conversionTime) {
      if (
        confirm(
          `CV時間「${conversionTime}」のレポートを削除します。よろしいですか？`
        )
      ) {
        let params = new URLSearchParams();
        params.append("report_id", reportId);
        this.$axios
          .post(`${this.rt}/api/tools02/delReport.php`, params)
          .then((res) => {
            console.log(res);
            if (res.data.status === 200) {
              this.getReportList("default", this.currentPage);
              return;
            } else if (res.data.status === 401) {
              alert(res.data.message);
              this.$router.push("/login");
            } else {
              alert(this.apiErrorMessage);
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return;
      }
    },
    editConversionTime(reportData) {
      // 
      this.editingConversionTimeReportData.reportId = reportData.reportId;

      // 
      const datetime = new Date(reportData.conversionTime);
      this.editingConversionTimeReportData.date = datetime.toISOString().split("T")[0].replace(/-/g, "/"); // "2024/12/17"
      this.editingConversionTimeReportData.hour = datetime.getHours();
      this.editingConversionTimeReportData.minute = datetime.getMinutes();
    },
    updateConversionTime(reportData) {
      // 
      const reportConversionDatetime = new Date(reportData.conversionTime);
      const originalHour = parseInt(reportConversionDatetime.getHours());
      const originalMinute = parseInt(reportConversionDatetime.getMinutes());
      const editedHour = parseInt(this.editingConversionTimeReportData.hour);
      const editedMinute = parseInt(this.editingConversionTimeReportData.minute);

      // 
      if (
        editedHour !== originalHour ||
        editedMinute !== originalMinute
      ) {
        // 
        if (editedHour >= 0 && editedHour <= 23) {
          // 
        } else {
          alert("時間は 0〜23 の範囲で入力してください。");
          return;
        }

        // 
        if (editedMinute >= 0 && editedMinute <= 59) {
          // ok!
        } else {
          alert("分は 0〜59 の範囲で入力してください。");
          return;
        }
      } else {
        alert("CV時間を編集してください。");
        return;
      }

      // 
      const editedDatetimeString = this.editingConversionTimeReportData.date + " " + String(editedHour).padStart(2, "0") + ":" + String(editedMinute).padStart(2, "0") + ":00";

      // 
      let message = ``;
      message += `クリックID: ${reportData.gclid}\n`;
      message += `オフラインCV名: ${reportData.promotionData.cvName}\n`;
      message += `アカウント名: ${reportData.accountData.accountName}\n`;
      message += `\n`;
      message += `この成果のCV時間を「${editedDatetimeString}」に修正します。よろしいですか？\n`;
      message += `※すでにGoogle広告にアップロード成功済みの場合、別成果として扱われ、重複で計測されるのでご注意ください。`;
      if (confirm(message)) {
        let params = new URLSearchParams();
        params.append("report_id", reportData.reportId);
        params.append("hour", editedHour);
        params.append("minute", editedMinute);
        this.$axios
          .post(`${this.rt}/l/api/tools02/report/update-conversion-time`, params)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              alert("更新しました。");
              this.editingConversionTimeReportData.reportId = null;
              this.getReportList("default", this.currentPage);
              return;
            } else if (response.data.status === 401) {
              alert(response.data.message);
              this.$router.push("/login");
            } else {
              alert(this.apiErrorMessage);
              return;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // no action.
      }
    }
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #409eff;
  text-decoration: none;
}

.manual {
  padding-left: 20px;
  font-size: 20px;
}

.sub-title {
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: 1202px) {
    flex-direction: column;
    align-items: flex-start;

    .manual {
      margin-top: 0;
      padding-left: 0;
    }
  }
}

.date {
  font-size: 15px;
  color: gray;
}

.last-step {
  margin: 0;

  h3 {
    margin-top: 50px;
  }

  p.description {
    margin-bottom: 60px;
  }
}

.el-select-prepend {
  min-width: 111px;
}

.select {
  min-width: 217px;
}

.today {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .today-bt {
    margin: 2px !important;

    @media screen and (max-width: 565px) {
      margin-top: 10px;
    }
  }
}

.total_tbl {
  tbody {
    tr {
      td {
        .conversion-time-editor {
          .el-input {
            width: 65px;
          }

          .update-button {
            margin-top: 3px;
          }
        }
      }
    }
  }
}
</style>
