<template>
  <div class="payment-active-plan-data">
    <h3 class="back-gray">カート・計測システム連携</h3>
    <div class="payment-config-cover">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>自動更新設定</span>
        </div>
        <div class="auto-update-config-cover">
          <span style="margin-right: 5px">OFF</span>
          <el-switch v-model="useCartSystemAutomaticPayment"></el-switch>
          <span style="margin-left: 5px">ON</span>
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>自動更新設定中のプラン名</span>
        </div>
        <div v-if="activeCartSystemSubscriptionData !== undefined">{{ activeCartSystemProductData.name }}</div>
        <div v-else>-</div>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>契約期間</span>
        </div>
        <div v-if="activeCartSystemSubscriptionData !== undefined" class="contract-term">{{
          activeCartSystemSubscriptionData.jpFormattedStartDate }} ～ {{
            activeCartSystemSubscriptionData.jpFormattedEndDate }}</div>
        <div v-else>-</div>
      </el-card>
    </div>
    <div class="description">お申し込みは、「<router-link to="/tools02/cart-system">カート・計測システム連携ツール</router-link>」の「<router-link
        to="/tools02/cart-system/0">お申込み</router-link>」からお手続きお願いします。</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Question from "@/components/Ui/Question.vue";

export default {
  name: "PaymentActivePlanData",
  components: {
    // Question,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "rt",
      "stockPoint",
      "userData",
      "activePlanData",
      "apiErrorMessage",
      "registeredCardList",
    ]),
    ...mapGetters("tools02", ["activeCartSystemSubscriptionData"]),
    activeCartSystemProductData() {
      // 
      let activeCartSystemProductData = null;
      if (this.activeCartSystemSubscriptionData !== undefined) {
        activeCartSystemProductData = this.$store.getters['tools02/cartSystemProductData'](this.activeCartSystemSubscriptionData.cartSystemProductId);
      } else {
        // no action.
      }

      // 
      return activeCartSystemProductData;
    },
    useCartSystemAutomaticPayment: {
      get() {
        return this.userData.useCartSystemAutomaticPayment;
      },
      set(useCartSystemAutomaticPayment) {
        // message
        let message = ``;
        message += `カート・計測システム連携の自動更新設定を${useCartSystemAutomaticPayment === true ? 'ON' : 'OFF'}にします。よろしいですか？`;

        //
        if (confirm(message)) {
          // 
          const registerData = {
            useCartSystemAutomaticPayment: useCartSystemAutomaticPayment
          }
          const params = new URLSearchParams();
          params.append('register_data', JSON.stringify(registerData));
          this.$axios
            .post(`${this.rt}/l/api/user/register`, params)
            .then(response => {
              console.log(response);
              if (response.data.status === 200) {
                this.$store.dispatch('setUserData');
              } else {
                alert(this.apiErrorMessage + "(setUserData)");
              }
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          return;
        }
      },
    },
  },
  methods: {
    stop() {
      let message = ``;
      message += `契約中のカートシステムの自動更新を停止します。よろしいですか？`;
      if (confirm(message)) {
        this.$axios
          .post(`${this.rt}/l/api/tools02/cart-system/stop-automatic-payment`)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              this.$store.dispatch('setUserData');
            } else {
              alert(this.apiErrorMessage);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        return;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.contract-term {
  font-size: 14px;
}

.description {
  margin-top: 1.5em;
}
</style>
