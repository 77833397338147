<template>
  <div class="Ctrl-parameter-log">
    <el-row>
      <el-col>
        <h2>決済完了</h2>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col v-if="toolData.codeKebab === 'cart-system'" :span="18">
        <p>カート・計測システム連携ツールのお申し込みありがとうございます。</p>
        <p>ご登録のメールアドレス宛に以下<span v-if="userData.chatworkRoomId === null">2通</span>のメールをお送りしました。</p>
        <p>件名：【アドタスカル】決済完了のお知らせ<br>
          <span v-if="userData.chatworkRoomId === null">件名：【アドタスカル】カート・計測システムの利用開始のご案内<br></span>
          送信元メールアドレス：noreply@adtasukaru.com
        </p>
        <p>しばらく経ってもメールが届かない場合は、迷惑メールフォルダに振り分けられている可能性がございます。</p>
        <p>迷惑メールフォルダにもない場合は、お問い合わせからご連絡お願いいたします。</p>
        <p>ご確認よろしくお願いいたします。</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="center">
          <el-button @click="$router.push('/')" type="primary">トップページへ</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
  },
  props: {
    toolId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['rt', 'userData']),
    toolData() {
      return this.$store.getters.toolData(this.toolId);
    }
  },
  watch: {},
  created() {
    if (this.toolData.codeKebab === 'cart-system') {
      this.$store.dispatch('tools02/setCartSystemSubscriptionList');
    } else {
      // no action.
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
