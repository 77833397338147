<template>
  <div class="tools02-report-import-easy-api">
    <el-collapse accordion>
      <el-collapse-item title="手動でAPI送信する" name="1">
        <el-tooltip class="item" effect="dark" placement="right">
          <div slot="content">
            <ul>
              <li>（※）は、必須項目です。</li>
            </ul>
          </div>
          <i class="el-icon-question"></i>
        </el-tooltip>
        <el-row type="flex" align="middle">
          <el-col :span="3">プログラム名<span class="red">（※）</span></el-col>
          <el-col :span="10">
            <el-select v-model="promotionId" placeholder="Select">
              <el-option v-for="(promotionData, index) in promotionList" :key="index"
                :label="promotionData.promotionName" :value="promotionData.promotionId">
              </el-option>
            </el-select>
            <ValidationErrorMessages :errorMessageList="validationErrorData.promotionId" />
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">CV日時<span class="red">（※）</span></el-col>
          <el-col :span="10">
            <el-date-picker v-model="conversionTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="">
            </el-date-picker>
            <ValidationErrorMessages :errorMessageList="validationErrorData.conversionTime" />
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">報酬額（税別）<span class="red">（※）</span></el-col>
          <el-col :span="4">
            <el-input v-model="conversionValue" type="number" min="0" />
            <ValidationErrorMessages :errorMessageList="validationErrorData.conversionValue" />
          </el-col>
          <el-col :span="12" :offset="1">報酬額(税別)×承認率の値がAPI送信に使用されます。</el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">ユニット<span class="red">（※）</span></el-col>
          <el-col :span="10">
            <el-select v-model="unitToken" placeholder="Select">
              <el-option v-for="unitData in units" :key="unitData.unitId" :label="unitData.unitName"
                :value="unitData.unitToken">
              </el-option>
            </el-select>
            <ValidationErrorMessages :errorMessageList="validationErrorData.unitToken" />
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">クリックID<span class="red">（※）</span></el-col>
          <el-col :span="10">
            <el-radio v-model="clickIdType" label="1">atid</el-radio>
            <el-radio v-model="clickIdType" label="2">fbclid</el-radio>
            <el-input v-model="clickId" />
            <ValidationErrorMessages :errorMessageList="validationErrorData.clickId" />
          </el-col>
        </el-row>
        <el-row>
          <!-- <el-col><span class="red">※必須項目</span></el-col>
          <el-col><span class="green">※ atidまたはfbclidのいずれか一方は必須です。両方分かる場合は両方入力してください。</span></el-col> -->
          <el-col><span>atidが分かる場合は、atidにチェックしてatidを入力してください。<br>atidが分からない場合は、fbclidにチェックして、fbclidを入力してください</span></el-col>
        </el-row>
        <div style="text-align: center">
          <el-button @click="sendApiRequests" type="primary">送信</el-button>
        </div>
        <el-row v-if="resultList.length > 0">
          <el-col>
            <h3>送信結果</h3>
            <div v-for="(resultData, index) in resultList" :key="index">
              <h4>ピクセル名: {{ resultData.pixelData.pixelName }}</h4>
              <div v-if="resultData.apiResponse.requestResultData.eventsReceived === true">
                API送信に成功しました。
              </div>
              <div v-else>
                <h5>
                  エラー種別:
                  {{ resultData.apiResponse.requestResultData.errorUserTitle }}
                </h5>
                <div>
                  {{ resultData.apiResponse.requestResultData.errorUserMsg }}
                </div>
              </div>
              <hr />
            </div>
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ValidationErrorMessages from "@/components/Ui/ValidationErrorMessages.vue";

export default {
  name: "tools02-report-import-easy-api",
  components: {
    ValidationErrorMessages,
  },
  props: {},
  data() {
    return {
      // 
      promotionId: "",
      conversionTime: "",
      conversionValue: 0,
      unitToken: "",
      clickIdType: "1",
      clickId: "",

      // 
      resultList: [],
      validationErrorData: {
        promotionId: [],
        conversionTime: [],
        conversionValue: [],
        unitToken: [],
        clickId: [],
      },

      // sendData: {
      //   cvPageUrl: "",
      //   conversionTime: "2022-05-09 14:22:00",
      //   conversionValue: 1234,
      //   unitToken: "61b160a905145db8e5cab18637a1c674",
      //   fbc: "fb.1.1554763741205.AbCdEfGhIjKlMnOpQrStUvWxYz1234567890",
      //   fbp: "fb.1.1558571054389.1098115397",
      //   ipAddress: "60.94.160.98",
      //   userAgent: "Mozilla/5.0",
      //   fbclid: "hogehoge",
      //   adtasukaruId: "aiueo",
      // },
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
    ...mapGetters("tools02", ["units", "facebookEventList"]),
    promotionList() {
      return this.$store.getters["tools02/promotionsInTool"]("8");
    },
  },
  watch: {},
  methods: {
    sendApiRequests() {
      let params = new URLSearchParams();
      params.append("promotion_id", this.promotionId);
      params.append("conversion_time", this.conversionTime === null ? "" : this.conversionTime);
      params.append("conversion_value", this.conversionValue);
      params.append("unit_token", this.unitToken);
      params.append("click_id_type", this.clickIdType);
      params.append("click_id", this.clickId);
      this.$axios
        .post(`${this.rt}/l/api/tools02/media/facebook/manual-send-conversion-event`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.resultList = response.data.resultList;
            return;
          } else if (response.data.status === 400) {
            this.validationErrorData = response.data.validationErrorData;
            return;
          } else if (response.data.status === 440) {
            alert(response.data.message);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendApiRequestsOld() {
      let params = new URLSearchParams();
      params.append("send_data", JSON.stringify(this.sendData));
      this.$axios
        .post(
          `${this.rt}/api/tools02/facebook-cv/easy-api-requests.php`,
          params
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.resultList = response.data.resultList;
            return;
          } else if (response.data.status === 400) {
            alert(response.data.message);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.green {
  color: green;
}
</style>
