<template>
  <div class="payment">
    <el-row>
      <el-col>
        <h2>ポイント購入・プラン変更</h2>
      </el-col>
      <el-col>
        <p>
          ポイントを購入して使用することで、アドタスカルのすべてのツール/機能を利用することができます。ポイントの有効期限は購入後1年間です。
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <RegisteredCard />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <PointPlanSubscriptionData />
        <ProductsTable />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <CartSystemSubscriptionData />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <ToolPointList />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <BonusPointList />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PointPlanSubscriptionData from "@/components/Payment/PointPlanSubscriptionData.vue";
import CartSystemSubscriptionData from "@/components/Payment/CartSystemSubscriptionData.vue";
import RegisteredCard from "@/components/Payment/RegisteredCard.vue";
import ProductsTable from "@/components/Ui/ProductsTable.vue";
import ToolPointList from "@/components/Payment/ToolPointList.vue";
import BonusPointList from "@/components/Payment/BonusPointList.vue";

export default {
  components: {
    PointPlanSubscriptionData,
    CartSystemSubscriptionData,
    RegisteredCard,
    ProductsTable,
    ToolPointList,
    BonusPointList,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "userData"]),
    cartSystemToolData() {
      return this.$store.getters.toolData('13');
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
:deep(h3.back-gray) {
  background-color: #808080;
  color: white;
  margin-bottom: 35px;
}

:deep(.payment-config-cover) {
  display: flex;
  justify-content: space-between;

  .el-card,
  .dummy {
    width: 30%;

    .el-card__header,
    .el-card__body {
      text-align: center;
      color: var(--black);
    }

    .el-card__header {
      font-weight: bold;
    }
  }

  .el-card {
    .auto-update-config-cover {
      .subscribing-cover {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .subscribing-item {
          width: 30%;
        }
      }
    }
  }
}
</style>
